import LocalDatabaseHandler from "./LocalDatabaseHandler.js";
import { convertAppfiltersToMongoFilterObject } from "./filter.js";

class SyncDatabase {
  constructor(props) {
    this.props = props;
    this.nodeData = new props.NodeData();
    this.nodeData.load(props);

    this.localDatabaseHandler = new LocalDatabaseHandler({
      ...props,
      processDbSync: (x) => this.processLocalDbSync(x),
    });

    this.dbSyncsStore = [];
    this.databases = null;

    this.start();
  }

  async start(props) {
    try {
      const { databases } = await this.props.api.socket("v1/app/database/get");
      this.databases = databases;

      const localDatabases = databases.filter((x) => x.type === "local");

      const dbsyncfilter = {
        filter: JSON.stringify({
          where: {
            synchToDatabase: { $in: localDatabases.map((x) => x._id) },
            status: "active",
          },
        }),
      };
      let { dbsyncs: _dbsync } = await this.props.api.socket(
        "v1/app/database/dbsync/get",
        dbsyncfilter
      );

      console.log({ dbsyncs: _dbsync });

      let dbsyncs = [];
      for (let i = 0; i < _dbsync.length; i++) {
        const dbsync = _dbsync[i];

        const filters = await this.nodeData.evalFilters(
          dbsync.synchFilter?.filters
        );

        dbsyncs = [...dbsyncs, { ...dbsync, filters }];
      }

      this.dbsyncs = dbsyncs;

      for (let i = 0; i < dbsyncs.length; i++) {
        try {
          const dbsync = dbsyncs[i];

          const synchFromDatabase =
            await this.props.databaseModule.getDataBaseInfo({
              dbId: dbsync.synchFromDatabase,
            });
          const synchFromTable = synchFromDatabase.tables.find(
            (x) => x._id === dbsync.synchFromTable
          );

          this.sync({
            dbId: dbsync.synchFromDatabase,
            tableId: dbsync.synchFromTable,
            tableName: synchFromTable.name,
          });

          const filters = dbsync.filters;
          const filter = await convertAppfiltersToMongoFilterObject(filters);
          const filterstr = JSON.stringify(filter);

          const roomName = `${dbsync.synchFromTable}-${filterstr || ""}`;
          
          console.warn("joing database sync socket room: ", roomName);
          this.props.utils.socketService.joinSocketRoom(roomName);

          const subid = this.props.utils.socketService.subscribeSocketEvent(
            "v1/database/write/dbsync",
            (data) => {
              this.sync(data);
            }
          );
        } catch (e) {
          console.log("Error in SyncDatabase.start " + e.message);
        }
      }
    } catch (error) {
      console.error("Error syncing database", error);
    }
  }

  async sync(data) {
    try {
      const dbsyncs = this.dbsyncs?.filter(
        (x) =>
          x.synchFromDatabase === data.dbId && x.synchFromTable === data.tableId
      );

      if (!dbsyncs.length) return;

      for (let i = 0; i < dbsyncs.length; i++) {
        const dbsync = dbsyncs[i];
        if (!dbsync.synchToDatabase || !dbsync.synchToTable) continue;

        const syncTime = Date.now();
        const lastSyncAtKey =
          "LAST_SYNC_" + dbsync.synchFromTable + "_" + dbsync.synchToTable;

        const lastSyncAtQuery = {
          dbId: "APP",
          tableName: "KEY_VALUE_PAIR",
          filters: [{ name: "key", value: lastSyncAtKey }],
          limit: 1,
        };
        const lastSyncAt =
          (await this.localDatabaseHandler.read(lastSyncAtQuery))?.data?.[0]
            ?.value || 0;

        const query = {
          dbId: data.dbId,
          tableName: data.tableName,
          tableId: data.tableId,
          filters: [
            ...dbsync.filters,
            {
              name: "updatedAt",
              value: lastSyncAt,
              condition: "gt",
            },
          ],
        };

        const latestRecordData = await this.props.databaseModule.read(query);

        for (let j = 0; j < latestRecordData.data?.length; j++) {
          const record = latestRecordData.data?.[j];

          let document = {};

          for (const key in record) {
            if (Object.hasOwnProperty.call(record, key)) {
              const value = record[key];
              document[key] = { value, method: "replace" };
            }
          }

          console.log({ record });
          await this.props.databaseModule.write({
            valueType: "editRecord",
            enableDbTrigger: true,
            document: document,
            dbId: dbsync.synchToDatabase,
            tableId: dbsync.synchToTable,
            upsert: true,
            filters: [
              {
                name: "_id",
                value: record._id,
              },
            ],
            skipUpdatedAt: true,
          });
        }

        await this.localDatabaseHandler.write({
          valueType: "editRecord",
          enableDbTrigger: false,
          document: {
            key: { value: lastSyncAtKey, method: "replace" },
            value: { value: syncTime, method: "replace" },
          },
          dbId: "APP",
          tableName: "KEY_VALUE_PAIR",
          upsert: true,
          filters: [
            {
              name: "key",
              value: lastSyncAtKey,
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error in syncing database", error);
    }
  }

  async loadDbSyncFromRemote(where) {
    const dbsyncfilter = {
      filter: JSON.stringify({
        where,
      }),
    };
    return this.props.api.socket("v1/app/database/dbsync/get", dbsyncfilter);
  }

  async loadDbSync(data) {
    const where = {
      synchFromDatabase: data.dbId,
      synchFromTable: data.tableId,
      status: "active",
    };

    const matchingFunc = (x) =>
      x.synchFromDatabase === where.synchFromDatabase &&
      x.synchFromTable === where.synchFromTable &&
      x.status === where.status;

    const item = this.dbSyncsStore.find(matchingFunc);

    if (item && Date.now() - item.ts < 1000 * 30) {
      return item.dbsyncs;
    }

    const { dbsyncs: _dbsync } = await this.loadDbSyncFromRemote(where);

    let dbsyncs = [];
    for (let i = 0; i < _dbsync.length; i++) {
      const dbsync = _dbsync[i];

      const filters = await this.nodeData.evalFilters(
        dbsync.synchFilter?.filters
      );

      dbsyncs = [...dbsyncs, { ...dbsync, filters }];
    }

    this.dbSyncsStore = [
      ...this.dbSyncsStore.filter((x) => !matchingFunc(x)),
      { ...where, ts: Date.now(), dbsyncs },
    ];

    return dbsyncs;
  }

  async processLocalDbSync({ data, records }) {
    if (["APP"].includes(data.dbId)) return;
    const dbsyncs = await this.loadDbSync(data);

    for (let recordIndex = 0; recordIndex < records.length; recordIndex++) {
      const record = records[recordIndex];

      for (let dbsyncIndex = 0; dbsyncIndex < dbsyncs.length; dbsyncIndex++) {
        const dbsync = dbsyncs[dbsyncIndex];

        if (this.matchDbSyncFilter(dbsync.filters, record)) {
          let document = {};

          for (const key in record) {
            if (Object.hasOwnProperty.call(record, key)) {
              const value = record[key];
              document[key] = { value, method: "replace" };
            }
          }

          const dbData = {
            valueType: "editRecord",
            dbId: dbsync.synchToDatabase,
            tableId: dbsync.synchToTable,
            tableName: this.getTableName({
              dbId: dbsync.synchToDatabase,
              tableId: dbsync.synchToTable,
            }),
            filters: [
              {
                name: "_id",
                value: record._id,
              },
            ],
            document: document,
            enableDbTrigger: true,
            upsert: true,
            skipUpdatedAt: true,
          };

          const synchResult = await this.props.databaseModule.write(dbData);

          console.log({ synchResult, dbData });
        }
      }
    }

    console.log({ dbsyncs, records, data });
  }

  matchDbSyncFilter(filters, record) {
    for (let i = 0; i < filters.length; i++) {
      const { name, value } = filters[i];

      if (name && record[name] !== value) return false;
    }
    return true;
  }

  getTableName({ dbId, tableId }) {
    return this.databases
      ?.find((x) => x._id === dbId)
      ?.tables?.find((x) => x._id === tableId)?.name;
  }
}

export default SyncDatabase;
