import NodeData from "../calculation/execution/NodeData.js";

class ElementDataProcessor {
  constructor({ domNode, availableProps, options }) {
    this.domNode = domNode;
    this.availableProps = availableProps;
    this.props = this.availableProps;
    this.options = options;

    this.nodeData = new NodeData();

    this.nodeData.load({
      ...this.props,
      setDependentElements: () => {},
      setDependentDbTable: () => {},
    });
  }

  async evaluate() {
    const elementType = this.domNode?.value?.elementType;

    const activeTab = await this.getActiveTab();

    const elementData = await this.loadElementData[elementType]?.(
      activeTab,
      this.options
    );

    console.log({
      elementData,
      activeTab,
      elementType,
      fn: this.loadElementData[elementType],
    });

    return {
      elementData,
      activeTab,
    };
  }

  async getActiveTab() {
    const { tab } = await this.nodeData.getConditionSatisfiedData(
      this.domNode?.value?.data,
      this.options
    );

    if (!tab) return null;

    const activeTabStyleData = tab?.styleData || {};
    let styleData = {};

    const os = "web";
    for (const key in activeTabStyleData) {
      if (Object.hasOwnProperty.call(activeTabStyleData, key)) {
        const platforms = activeTabStyleData[key];

        styleData[key] = platforms[os];
      }
    }

    return { ...tab, styleData };
  }

  loadElementData = {
    container: async (activeTab, options) => {
      const containerData = activeTab?.containerData;
      if (["screen"].includes(activeTab?.containerType?.type)) {
        let screenConfig = activeTab?.containerType?.screenConfig || {};
        if (screenConfig.urlParameters?.length) {
          await Promise.all(
            screenConfig.urlParameters?.map(async (urlParam) => {
              ["parameterName", "parameterValue"].map(async (key) => {
                if (urlParam[key]) {
                  let value = await this.nodeData
                    .evaluateQuickSelectionValue(urlParam[key], this.options)
                    .catch((e) => console.warn(e));
                  urlParam[key]["value"] = value?.value;
                }
              });
            })
          );
        }
        screenConfig.loadCompleted = Date.now();
      }

      const repeatingData = containerData?.repeating
        ? await this.repeatingData(containerData, options)
        : { value: [{ _id: "DEFAULT" }] };

      const repeatingContainers = repeatingData?.value?.map((row, i) => ({
        uid: row._id || row.id || i,
        row,
      }));

      return {
        ...repeatingData,
        value: JSON.stringify(repeatingData.value),
        repeatingContainers,
      };
    },
    text: async (activeTab, options) => {
      const textData = activeTab?.textData;
      const textValue = (
        await Promise.all(
          (textData?.textParts || []).map((part) => this.evalTextPart(part))
        )
      )
        .join("")
        .trim();

      return { valueType: "string", value: textValue };
    },
    image: async (activeTab, options) => {
      const data = activeTab?.imageData || {};
      const value = await this.nodeData
        .evaluateQuickSelectionValue({
          ...data,
          valueType: data.valueType || "staticUrls",
        })
        .catch(console.warn);

      return {
        ...(value || {}),
        valueType: "valueArray",
        value: this.toValueArray(value?.value).filter((x) => !!x),
      };
    },
  };

  async repeatingData(containerData, options = {}) {
    const repeatingContainers =
      this.props.dataStore.data[this.props.domNode.id]?.[
        this.props.rowIds.join()
      ]?.repeatingContainers;

    const rowsResult = await this.nodeData.loadRepeatingContainerRow(
      containerData,
      {
        ...this.availableProps,
        ...options,
        items: options.reload ? null : repeatingContainers,
      }
    );

    return rowsResult;
  }

  async evalTextPart(part) {
    const result = await this.nodeData.evaluateQuickSelectionValue(part);

    return [null, undefined].includes(result?.value)
      ? ""
      : this.toStringSync(result?.value);
  }

  toStringSync(value) {
    return [null, undefined].includes(value)
      ? ""
      : typeof value === "object" && value instanceof Array
      ? value.map((x) => x?.value).join()
      : value?.toString();
  }

  toValueArray(value) {
    switch (typeof value) {
      case "object":
        return value instanceof Array
          ? value.map((x) => (typeof x === "object" ? x : { value: x }))
          : [value];

      case "string":
        return value?.split(",").map((x) => ({ value: x.trim() }));
      default:
        return [value];
    }
  }

  parseDate(x) {
    return !x && x !== 0
      ? null
      : isNaN(x)
      ? new Date(x)
      : new Date(parseInt(x));
  }
}

class RenderDomNode {
  constructor(props) {
    this.props = props;
  }

  async render() {
    const { domNode } = this.props;
    if (!domNode || !domNode.value) return "";

    const { id, value, children } = domNode;
    const elementType = value.elementType;

    const dataProcessor = new ElementDataProcessor({
      domNode,
      availableProps: this.props,
      options: { ...this.options, ...this.props },
    });
    const { elementData, activeTab } = await dataProcessor.evaluate();

    if (!activeTab) return "";

    const repeatingData = ["container"].includes(elementType)
      ? elementData?.repeatingContainers
      : [{ uid: "DEFAULT" }];

    for (let index = 0; index < repeatingData?.length; index++) {
      const item = repeatingData[index];

      const rowIndices = [...this.props.rowIndices, index];
      const rowIds = [...this.props.rowIds, item.uid];
      const passedParameter = {
        sourceType: "repeatingContainer",
        elementId: this.props.domNode.id,
        rowIndices,
        rowIds,
        repeatingContainer: item,
      };
      const passedParameters = [
        ...(this.props.passedParameters || []),
        passedParameter,
      ];

      const activeTabStyle = activeTab?.styleData?.[elementType];
      const hoverStyle = {
        ...(activeTabStyle || {}),
        ...(activeTab?.styleData?.hover || {}),
      };

      let style = await this.convertStyleToInline(activeTabStyle);

      const propsToPass = {
        ...this.props,
        activeTab: activeTab,
        elementData,
        rowIndices,
        rowIds,
        passedParameters,
        intermediateProps: null,
        style,
      };

      const renderedElement = await this.renderBasedOnElementType(
        elementType,
        propsToPass
      );

      return renderedElement;
    }
  }

  renderBasedOnElementType(elementType = "", propsToPass) {
    if (elementType === "container") {
      return this.renderContainer(propsToPass);
    } else if (elementType === "text") {
      return this.renderText(propsToPass);
    } else if (elementType === "image") {
      return this.renderImage(propsToPass);
    }
  }

  async renderContainer(props) {
    const { style } = props;

    return `<div style="${style}">${await this.renderChildren(props)}</div>`;
  }

  renderText(props) {
    const { elementData, style } = props;

    const value = elementData?.value || "";

    return `<div style="${style}">${value}</div>`;
  }

  renderImage(props) {
    const { elementData, style, activeTab } = props;

    const imageDisplayMode = activeTab?.imageDisplayMode;

    const valueArray = ["repeating", "gallery"].includes(imageDisplayMode)
      ? elementData?.value || []
      : [elementData?.value?.[0]];

    return valueArray
      .map(
        (item, i) =>
          `<img style="${style}" src="${item?.value?.toString()}" alt="img"/>`
      )
      .join("");
  }

  async renderChildren(props) {
    const { domNode, indices } = props;

    const promises = (domNode.children || []).map((domNode, i) => {
      const renderDomNode = new RenderDomNode({
        ...props,
        key: domNode.id,
        domNode,
        indices: [...indices, i],
      });

      return renderDomNode.render();
    });

    const array = await Promise.all(promises);
    return array.join("");
  }

  convertStyleToInline(styleObj) {
    if (!styleObj || Object.keys(styleObj).length === 0) return "";

    let inlineStyle = "";
    Object.entries(styleObj).forEach(([key, value]) => {
      const cssKey = key.replace(/([A-Z])/g, "-$1").toLowerCase();
      const cssValue = value;
      // const cssValue = typeof value === "number" ? `${value}px` : value;
      inlineStyle += `${cssKey}: ${cssValue}; `;
    });

    return inlineStyle.trim();
  }
}

export class HtmlBuilder {
  constructor({ availableProps }) {
    this.availableProps = availableProps;
  }

  async generateHtml({ json }, options = {}) {
    this.json = json;
    this.options = options;

    const renderDomNode = new RenderDomNode({
      ...this.availableProps,
      domNode: this.json.dom,
      indices: [0],
      rowIndices: [],
    });

    const html = await renderDomNode.render();
    return html;
  }
}
