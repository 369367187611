import procesDocument from './processDocument.js';
import _ from 'lodash';

class LocalDatabaseHandler {
  constructor(props) {
    this.props = props;
  }

  write(payload) {
    if (!this.operations[payload.valueType]) {
      throw new Error('Invalid database operation ' + payload.valueType);
    }

    return this.operations[payload.valueType](payload);
  }

  async read(payload) {
    if (!payload.dbId || !payload.tableName) {
      console.log({payload})
      throw new Error('Invalid table name or database id');
    }

    const database = await this.props.getDatabase(payload.dbId);
    let query = {
      ..._.omit(payload, ['database', 'tableId']),
    };

    const rows = await database.read(query);
    return {data: rows};
  }

  operations = {
    addRecord: async (payload) => {
      if (!payload.document) {
        throw new Error('Missing document field');
      }
      if (!payload.dbId || !payload.tableName) {
        throw new Error('Invalid table name or database id');
      }

      const database = await this.props.getDatabase(payload.dbId);
      const document = await procesDocument(payload.document, null, payload);
      const data = await database.insert(document, payload.tableName);

      this.props.processDbSync({ data: payload, records: [data] });
      return data;
    },

    editRecord: async (payload) => {
      if (!payload.document) {
        throw new Error("Missing document field");
      }
      if (!payload.dbId || !payload.tableName) {
        throw new Error("Invalid table name or database id");
      }

      const database = await this.props.getDatabase(payload.dbId);


      let limit = payload.multiple ? 0 : 1;

      let query = {
        ..._.pick(payload, ["dbId", "tableName", "filters"]),
        limit,
      };

      let existingRecords = await database.read(query);
      if (payload.upsert && !existingRecords.length) existingRecords = [null];

      let updatedRecords = [];

      for (let i = 0; i < existingRecords.length; i++) {
        const existingRecord = existingRecords[i];
        let rowId = existingRecord?._id;
        let document = await procesDocument(payload.document, existingRecord, payload);

        let data = null;
        if (rowId) {
          let expectedDbResult = {
            ...existingRecord,
            ...document,
          };
          // console.log({existingRecord, document, expectedDbResult, changed: this.compareObjects(existingRecord, expectedDbResult)})
          if (!this.compareObjects(existingRecord, expectedDbResult)) {
            data = await database.updateById(
              rowId,
              document,
              payload.tableName
            );

            updatedRecords.push(data);
          }
        } else if (payload.upsert) {
          document = this.prepareUpsertdbObj({
            filters: payload.filters,
            dbObj: document,
          });
          data = await database.insert(document, payload.tableName);

          updatedRecords.push(data);
        } else {
          throw new Error("Edit record failed. No row found");
        }
      }

      if (updatedRecords.length) this.props.processDbSync({ data: payload, records: updatedRecords });

      console.log({updatedRecords})
      return updatedRecords;
    },

    deleteRecord: async payload => {
      if (!payload.dbId || !payload.tableName) {
        throw new Error('Invalid table name or database id');
      }

      const database = await this.props.getDatabase(payload.dbId);

      let query = {
        ..._.pick(payload, ['dbId', 'tableName', 'filters']),
      };

      const rows = await database.read(query);

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        await database.deleteById(row._id, query.tableName);
      }

      console.log(rows);
      this.props.processDbSync({ data: payload, records: rows });

      return rows;
    },
  };

  prepareUpsertdbObj({filters, dbObj}) {
    let upsertDbObj = {};
    filters.map(x => {
      if (!['[OR]', '[AND]'].includes(x.name)) upsertDbObj[x.name] = x.value;
      return null;
    });

    upsertDbObj = {...upsertDbObj, ...dbObj};
    return upsertDbObj;
  }

  compareObjects(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        if (!["updatedAt"].includes(key)) return false;
      }
    }

    return true;
  }
}


export default LocalDatabaseHandler;
