import React from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import AppModal from "../../Components/Models/app-modal";
import api from "../../Services/Api/api";
import { deviceId } from "../../Providers/deviceId";
import {
  subscribeSocketEvent,
  unsubscribeSocketEvent,
} from "../../Services/Socket/socketListeners";
import config from "../../Config";
import callModule from "../../Modules/call/call-module";
import { getDatastore } from "../../Providers/datastore";
import databaseModule from "../../Modules/database";

class MeetingScreen extends React.Component {
  state = {
    cameraFacingMode: "user",
    availableVideoDevices: [],
  };

  peerDataList = [];
  candidates = {};

  componentDidMount() {
    this.initializeWebRTC().catch((error) => {
      console.error("Error accessing media devices:", error);
    });

    this.subscribeSocketEvents();
  }

  componentWillUnmount() {
    this.endCall();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMuted !== this.props.isMuted) this.updateAudioTracks();
    if (prevProps.isCameraDisabled !== this.props.isCameraDisabled)
      this.updateVideoTracks();

    if (this.onComponentUpdate) this.onComponentUpdate(prevProps, prevState);
  }

  onComponentUpdate() {}

  async setAvaialableVideoDevices() {
    const devices = await navigator?.mediaDevices?.enumerateDevices?.();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    this.setState({ availableVideoDevices: videoDevices });
  }

  getLocalStream(roomType) {
    return navigator.mediaDevices.getUserMedia(
      roomType === "video"
        ? {
            video: true,
            audio: true,
          }
        : { video: false, audio: true }
    );
  }

  endCall = () => {
    try {
      this.unSubscribeSocketEvents();

      this.peerDataList.forEach((peerData) => {
        peerData.peerConnection.close();
        console.log("peerConnection closed: ", peerData);
      });

      // Stop the local media stream
      if (this.props.onGoingCall?.localStream) {
        const tracks = this.props.onGoingCall.localStream.getTracks();
        tracks.forEach((track) => track.stop());
      }

      api
        .socket("v1/webrtc/hangup", {
          rtcroom: this.props.onGoingCall.rtcroom?._id,
        })
        .catch((e) => console.warn("Error sending hangup signal: ", e));

      this.addRtcLog(deviceId, "disconnected");

      // Clear ongoing call state
      this.props.setOnGoingCall(null, true);
    } catch (e) {
      console.warn("Error ending call: ", e.messsage);
    }
  };

  subscribeSocketEvents(socketEvents) {
    // const socket = getSocket();
    (socketEvents || this.socketEvents).forEach((event) => {
      console.log("listening socket events: ", event.eventString);

      const subid = subscribeSocketEvent(event.eventString, (...arg) => {
        if (arg[0]?.rtcroom?._id === this.props.onGoingCall.rtcroom?._id) {
          const id = Math.random();
          console.log("webrtc-evnet" + id, event.eventString, arg);
          event.fn(...arg);
          console.log("done: webrtc-event: " + id, event.eventString, arg);
        } else {
          console.log("Socket event received for different call", arg);
        }
      });
      this.subscribeIds = [
        ...this.subscribeIds,
        { id: subid, eventString: event.eventString },
      ];
      //   socket.on(event.eventString, );
    });
  }

  unSubscribeSocketEvents() {
    this.subscribeIds.forEach(({ id, eventString }) =>
      unsubscribeSocketEvent(eventString, id)
    );
    // const socket = getSocket();

    // this.socketEvents.forEach((event) => {
    //   socket.off(event.eventString);
    //   console.log("stopped listening socket events: ", event.eventString);
    // });
  }

  subscribeIds = [];
  socketEvents = [
    {
      eventString: "v1/webrtc/candidate",
      fn: this.onIceCandidate.bind(this),
    },
    {
      eventString: "v1/webrtc/offer",
      fn: this.onOffer.bind(this),
    },
    {
      eventString: "v1/webrtc/answer",
      fn: this.onAnswer.bind(this),
    },
    {
      eventString: "v1/webrtc/hangup",
      fn: this.onHangUp.bind(this),
    },
  ];

  async getTargetPeerData({ deviceId, rtcroom }, opt = {}) {
    let targetPeerData = this.peerDataList.find(
      (peerData) => peerData.peer.deviceId === deviceId
    );

    if (!targetPeerData && opt.upsert) {
      const peer = rtcroom.peers.find((x) => x.deviceId === deviceId);
      if (peer) {
        targetPeerData = await this.addPeer({
          peer,
          localStream: this.props.onGoingCall.localStream,
        });
      }
    }

    return targetPeerData;
  }

  async onIceCandidate(data) {
    try {
      const { candidate } = data;

      const targetPeerData = await this.getTargetPeerData(data);
      const targetPeerConnection = targetPeerData?.peerConnection;

      if (targetPeerConnection) {
        await targetPeerConnection.addIceCandidate(
          new RTCIceCandidate(candidate)
        );
      } else {
        console.warn(
          "Peer connection not found for ICE candidate:",
          data,
          this.peerDataList
        );
      }
    } catch (error) {
      console.error("Error handling ICE candidate:", error);
    }
  }

  async onOffer(data) {
    try {
      // Extract relevant data from the incoming message
      const { offer, candidates } = data;

      // Find the corresponding peerConnection
      const targetPeerData = await this.getTargetPeerData(data, {
        upsert: true,
      });
      const targetPeerConnection = targetPeerData?.peerConnection;

      if (targetPeerConnection) {
        // if (targetPeerConnection.signalingState !== "stable") {
        // Set the remote offer as the description
        await targetPeerConnection.setRemoteDescription(
          new RTCSessionDescription(offer)
        );

        // Create an answer
        const answer = await targetPeerConnection.createAnswer();
        await targetPeerConnection.setLocalDescription(answer);

        for (let i = 0; i < candidates?.length; i++) {
          const candidate = candidates[i];
          await targetPeerConnection.addIceCandidate(
            new RTCIceCandidate(candidate)
          );
        }

        // Send the answer to the remote peer
        api.socket("v1/webrtc/answer", {
          peer: targetPeerData.peer,
          answer,
          candidates: this.candidates[targetPeerData.peer.deviceId],
          rtcroom: this.props.onGoingCall.rtcroom?._id,
        });
        // } else {
        //   console.warn(
        //     "Cannot set local description. Signaling state is not stable."
        //   );
        // }
      } else {
        console.warn(
          "Peer connection not found for offer:",
          data,
          this.peerDataList
        );
      }
    } catch (error) {
      console.error("Error handling offer:", error);
    }
  }

  async onAnswer(data) {
    try {
      // Extract relevant data from the incoming message
      const { answer, candidates } = data;

      // Find the corresponding peerConnection
      const targetPeerData = await this.getTargetPeerData(data);
      const targetPeerConnection = targetPeerData?.peerConnection;

      if (targetPeerConnection) {
        if (!["stable"].includes(targetPeerConnection.signalingState)) {
          // Set the remote answer as the description
          await targetPeerConnection.setRemoteDescription(
            new RTCSessionDescription(answer)
          );
        }

        for (let i = 0; i < candidates?.length; i++) {
          const candidate = candidates[i];
          await targetPeerConnection.addIceCandidate(
            new RTCIceCandidate(candidate)
          );
        }
      } else {
        console.warn("Peer connection not found for answer:", data);
      }
    } catch (error) {
      console.error("Error handling answer:", error);
    }
  }

  async onHangUp(data) {
    try {
      if (this.props.onGoingCall.rtcroom?.callType === "call") {
        this.endCall();
      } else {
        this.peerDataList = this.peerDataList.filter(
          (peerData) => peerData.peer.deviceId !== data.peer.deviceId
        );

        const updatedRemoteStreams =
          this.props.onGoingCall.remoteStreams.filter(
            (remoteStream) => remoteStream.peer.deviceId !== data.peer.deviceId
          );

        this.props.setOnGoingCall({
          remoteStreams: updatedRemoteStreams,
        });
      }
    } catch (error) {
      console.error("Error handling hang-up:", error);
    }
  }

  async initializeWebRTC() {
    this.peerDataList = [];

    this.setAvaialableVideoDevices();

    const { onGoingCall, setOnGoingCall } = this.props;
    const rtcroom = onGoingCall.rtcroom;

    const localStream = await this.getLocalStream(rtcroom.roomType);
    setOnGoingCall({ localStream });

    await api.socket("v1/webrtc/joinroom", {
      rtcroom: rtcroom._id,
    });

    const peers = rtcroom?.peers?.filter(
      (peer) => peer.status === "active" && peer.deviceId !== deviceId
    );

    peers.forEach((peer) =>
      this.addPeer({ peer, localStream }).catch((e) =>
        console.error("Error adding peer: ", e)
      )
    );
  }

  async addRtcLog(deviceId, status) {
    try {
      console.log("addRtcLog", deviceId, status);
      const rtclogger = this.props.projectData?.data?.rtclogger || {};

      if (rtclogger.dbId && rtclogger.tableId) {
        const payload = {
          valueType: "editRecord",
          upsert: true,
          document: {
            webrtcRoomId: {
              method: "replace",
              value: this.props.onGoingCall?.rtcroom?._id,
            },
            deviceId: {
              method: "replace",
              value: deviceId,
            },
            dataType: {
              method: "replace",
              value: "rtclog",
            },
            status: {
              method: "replace",
              value: status,
            },
            updatedAt: {
              method: "replace",
              value: Date.now(),
            },
            
          },
          enableDbTrigger: false,
          dbId: rtclogger.dbId,
          tableId: rtclogger.tableId,
          filters: [
            {
              condition: "eq",
              name: "deviceId",
              value: deviceId,
            },
            {
              condition: "lt",
              name: "updatedAt",
              value: Date.now(),
            },
          ],
        };

        await databaseModule.write(payload);
      }
    } catch (e) {
      console.error("Error in add rtc log ", e);
    }
  }

  async addPeer({ peer, localStream }) {
    const { onGoingCall, setOnGoingCall } = this.props;
    const rtcroom = onGoingCall.rtcroom;

    const peerConnection = new RTCPeerConnection({
      iceServers: config.webrtc.iceServers,
    });

    // Add local stream to the connection
    localStream
      .getTracks()
      .forEach((track) => peerConnection.addTrack(track, localStream));

    // Handle ICE candidate events
    peerConnection.onicecandidate = (event) => {
      console.log("onicecandidate", event);
      if (event.candidate) {
        this.candidates[peer.deviceId] = this.candidates[peer.deviceId] || [];
        this.candidates[peer.deviceId].push(event.candidate);
        api.socket("v1/webrtc/candidate", {
          peer,
          candidate: event.candidate,
          rtcroom: rtcroom?._id,
        });
      }
    };

    // Handle negotiation needed event
    peerConnection.onnegotiationneeded = async () => {
      try {
        // Create an offer and set it as the local description
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        api.socket("v1/webrtc/offer", {
          peer,
          candidates: this.candidates[peer.deviceId],
          offer: offer,
          rtcroom: rtcroom?._id,
        });
      } catch (error) {
        console.error("Error creating offer:", error);
      }
    };

    // Handle the stream from the remote peer
    peerConnection.ontrack = (event) => {
      console.log("pc.ontrack: ", event);
      const remoteStream = event.streams[0];

      if (
        !this.props.onGoingCall.remoteStreams?.find(
          (x) => x.stream.id === remoteStream.id
        )
      ) {
        if (["calling", "incoming"].includes(this.state.visibleModal)) {
          // this.setState({ visibleModal: "inCall" });
          this.setState({ visibleModal: null });
        }

        this.addRtcLog(peer?.deviceId, "connected");
        setOnGoingCall({
          remoteStreams: [
            ...(this.props.onGoingCall.remoteStreams || []).filter(
              (x) => x?.peer?.deviceId !== peer?.deviceId
            ),
            { peer, peerConnection, stream: remoteStream },
          ],
        });
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      if (peerConnection.iceConnectionState == "disconnected") {
        console.log("Disconnected", { peer, peerConnection });
        this.addRtcLog(peer?.deviceId, peerConnection.iceConnectionState);
        this.onHangUp({ peer });
      }
    };

    const peerData = { peerConnection, peer };
    this.peerDataList = [...this.peerDataList, peerData];

    console.log({ peerDataList: this.peerDataList });

    return peerData;
  }

  toggleMute = () => {
    this.setState(
      (prevState) => ({ isMuted: !prevState.isMuted }),
      () => {
        this.updateAudioTracks();
      }
    );
  };

  toggleVideoVisibility = () => {
    this.setState(
      (prevState) => ({ isCameraDisabled: !prevState.isCameraDisabled }),
      () => {
        this.updateVideoTracks();
      }
    );
  };

  toggleCameraFacingMode = () => {
    this.setState(
      (prevState) => ({
        cameraFacingMode:
          prevState.cameraFacingMode === "user" ? "environment" : "user",
      }),
      () => {
        this.updateCameraTracks().catch(console.error);
      }
    );
  };

  updateAudioTracks = () => {
    const { onGoingCall } = this.props;
    const { isMuted } = this.props;

    onGoingCall.localStream.getAudioTracks()?.forEach((track) => {
      track.enabled = !isMuted;
    });
  };

  updateVideoTracks = () => {
    const { onGoingCall } = this.props;
    const { isCameraDisabled } = this.props;

    onGoingCall.localStream.getVideoTracks()?.forEach((track) => {
      track.enabled = !isCameraDisabled;
    });
  };

  // updateCameraTracks = () => {
  //   const { onGoingCall } = this.props;
  //   const { cameraFacingMode } = this.state;

  //   onGoingCall.localStream.getVideoTracks().forEach((track) => {
  //     track.applyConstraints({ facingMode: cameraFacingMode });
  //   });
  // };

  updateCameraTracks = async () => {
    const { onGoingCall } = this.props;
    const { cameraFacingMode } = this.state;

    onGoingCall.localStream?.getTracks().forEach((track) => {
      track.stop();
    });

    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: cameraFacingMode },
        audio: true,
      });

      this.props.setOnGoingCall({ localStream: newStream });

      setTimeout(() => {
        // Update the tracks in the peer connections
        this.peerDataList?.forEach((peerData) => {
          const { peerConnection } = peerData;
          const senders = peerConnection.getSenders();

          senders.forEach((sender) => {
            const { track } = sender;
            const newTrack = newStream
              .getTracks()
              .find((t) => t.kind === track.kind);

            if (newTrack) {
              sender.replaceTrack(newTrack);
            }
          });
        });
      }, 0);
    } catch (error) {
      console.error("Error updating camera tracks:", error);
    }
  };

  renderModel() {
    const { onGoingCall } = this.props;

    const modalContent = (
      <div>
        {onGoingCall.remoteStreams?.map(
          ({ peer, peerConnection, stream }, i) => (
            <div key={stream.id}>
              <VideoView stream={stream} />
              <div>
                Remote Stream {i}:{" "}
                {JSON.stringify({
                  id: stream.id,
                  active: stream.active,
                  deviceId: peer.deviceId,
                  signalingState: peerConnection?.signalingState,
                })}
              </div>
            </div>
          )
        )}
        <div>
          <VideoView key="local" stream={onGoingCall.localStream} muted />
          <div style={{ whiteSpace: "pre" }}>
            Local Stream{" "}
            {JSON.stringify(
              {
                id: onGoingCall.localStream?.id,
                active: onGoingCall.localStream?.active,
                deviceId: deviceId,
                cameraFacingMode: this.state.cameraFacingMode,
                availableVideoDevices: this.state.availableVideoDevices.length,
              },
              null,
              4
            )}
          </div>
        </div>
        <div>
          <button onClick={this.toggleMute}>
            {this.props.isMuted ? "Unmute" : "Mute"}
          </button>
          <button onClick={this.toggleVideoVisibility}>
            {this.props.isCameraDisabled ? "Show Video" : "Hide Video"}
          </button>
          <button
            disabled={this.state.availableVideoDevices.length <= 1}
            onClick={this.toggleCameraFacingMode}
          >
            Flip Camera
          </button>
          <button onClick={this.endCall}>End Call</button>
        </div>
      </div>
    );

    return (
      <>
        <AppModal
          {...{
            title: "",
            showFooter: false,
            showHeader: false,
            handleClose: () => {
              this.endCall();
              // setOnGoingCall(null, true);
            },
            visible: true,
            modalProps: {
              size: "xl",
            },
          }}
        >
          {modalContent}
        </AppModal>
      </>
    );
  }

  render() {
    return null;
  }
}

class CallScreen extends MeetingScreen {
  state = {
    visibleModal: null, // loading, calling, incoming, inCall
    isMuted: false,
    isCameraDisabled: false,
    cameraFacingMode: "user",
    availableVideoDevices: [],
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      const { onGoingCall } = this.props;

      if (!onGoingCall.rtcroom) {
        await this.startCall();
      } else if (
        onGoingCall.rtcroom.callType === "call" &&
        onGoingCall.rtcroom.invites.map((x) => x.deviceId).includes(deviceId)
      ) {
        await this.showIncoming();
      } else {
        await this.endCall();
      }
    } catch (e) {
      console.error("Error loading call: ", e);
    }
  }

  async startCall() {
    const {
      onGoingCall: { data },
      setOnGoingCall,
    } = this.props;

    const caller = {
      name: data.callerName,
      photo: data.callerPhoto,
      deviceId: deviceId,
    };

    const receiver = {
      name: data.receiverName,
      photo: data.receiverPhoto,
      deviceId: data.receiverDeviceId,
    };

    const payload = {
      caller: {
        userData: caller,
        deviceId: caller.deviceId,
      },
      invites: [
        {
          userData: receiver,
          deviceId: receiver.deviceId,
        },
      ],
      roomType: data.roomType,
    };

    this.setState({ visibleModal: "calling" });

    if (data.roomType === "video") {
      this.setAvaialableVideoDevices();
    }

    const localStream = await this.getLocalStream(data.roomType);

    setOnGoingCall({ localStream });

    const res = await api.socket("v1/webrtc/call", payload);
    setOnGoingCall({ rtcroom: res.rtcroom });

    setTimeout(() => {
      this.subscribeSocketEvents();
    }, 100);

    setTimeout(() => {
      if (this.state.visibleModal === "calling") this.endCall();
    }, 10 * 1000);
  }

  async showIncoming() {
    this.setState({ visibleModal: "incoming" });

    this.subscribeSocketEvents(
      this.socketEvents.filter((x) => x.eventString === "v1/webrtc/hangup")
    );
  }

  async answerCall() {
    // this.setState({ visibleModal: "inCall" });
    this.setState({ visibleModal: null });

    this.subscribeSocketEvents(
      this.socketEvents.filter((x) => x.eventString !== "v1/webrtc/hangup")
    );

    await this.initializeWebRTC().catch((e) => {
      window.alert(e.message);
      this.endCall();
    });

    this.props.onCallAnswer();
  }

  renderCalling() {
    const data = this.props.onGoingCall.data;

    const videoCalling = (
      <div>
        <div>Calling</div>
        <div>{data?.receiverName}</div>
        <img src={data?.receiverPhoto} />
        {/* <VideoView
          key="local"
          stream={this.props.onGoingCall.localStream}
          muted
        /> */}
        <button onClick={this.endCall}>End Call</button>
      </div>
    );

    const audioCalling = (
      <div>
        <div>Calling</div>
        <div>{data?.receiverName}</div>
        <img src={data?.receiverPhoto} />
        <button onClick={this.endCall}>End Call</button>
      </div>
    );
    return data.roomType === "video" ? videoCalling : audioCalling;
  }

  renderIncoming() {
    const rtcroom = this.props.onGoingCall?.rtcroom;
    const data = rtcroom?.caller?.userData || {};

    const videoCalling = (
      <div>
        <div>Incoming Video Call</div>
        <div>{data?.name}</div>
        <img src={data?.photo} />
        <div>
          <button onClick={this.answerCall.bind(this)}>Answer</button>
          <button onClick={this.endCall}>Reject</button>
        </div>
      </div>
    );

    const audioCalling = (
      <div>
        <div>Incoming Audio Call</div>
        <div>{data?.name}</div>
        <img src={data?.photo} />
        <div>
          <button onClick={this.answerCall.bind(this)}>Answer</button>
          <button onClick={this.endCall}>Reject</button>
        </div>
      </div>
    );
    return rtcroom.roomType === "video" ? videoCalling : audioCalling;
  }

  renderInCall() {
    return null;
    const onGoingCall = this.props.onGoingCall;
    const rtcroom = onGoingCall?.rtcroom;
    const userData = rtcroom?.caller?.userData || {};

    const videoCalling = (
      <div>
        <div>Incoming Video Call</div>
        <div>{userData?.name}</div>
        <img src={userData?.photo} />

        {onGoingCall.remoteStreams?.map(({ stream }, i) => (
          <div key={stream.id}>
            <VideoView stream={stream} />
          </div>
        ))}
        <VideoView key="local" stream={onGoingCall.localStream} muted />

        <div>
          <button onClick={this.toggleMute}>
            {this.props.isMuted ? "Unmute" : "Mute"}
          </button>
          <button onClick={this.toggleVideoVisibility}>
            {this.props.isCameraDisabled ? "Show Video" : "Hide Video"}
          </button>
          <button
            disabled={this.state.availableVideoDevices.length <= 1}
            onClick={this.toggleCameraFacingMode}
          >
            Flip Camera
          </button>
          <button onClick={this.endCall}>End Call</button>
        </div>
      </div>
    );

    const audioCalling = (
      <div>
        <div>In Call</div>
        <div>{userData?.name}</div>
        <img src={userData?.photo} />
        {onGoingCall.remoteStreams?.map(({ stream }, i) => (
          <div key={stream.id}>
            <VideoView stream={stream} style={{ display: "none" }} />
          </div>
        ))}

        <div>
          <button onClick={this.toggleMute}>
            {this.props.isMuted ? "Unmute" : "Mute"}
          </button>

          <button onClick={this.endCall}>End Call</button>
        </div>
      </div>
    );
    return rtcroom.roomType === "video" ? videoCalling : audioCalling;
  }

  render() {
    const {
      state: { visibleModal },
    } = this;

    return (
      <AppModal
        {...{
          title: "",
          showFooter: false,
          showHeader: false,
          handleClose: () => {
            this.endCall();
          },
          visible: !!visibleModal,
          modalProps: {
            size: "xl",
          },
        }}
      >
        {{
          calling: this.renderCalling.bind(this),
          incoming: this.renderIncoming.bind(this),
          inCall: this.renderInCall.bind(this),
        }[visibleModal]?.() || null}
      </AppModal>
    );
  }
}

class VideoView extends React.Component {
  state = {
    visible: !!this.props.stream,
  };
  videoView = React.createRef(null);

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.stream && this.props.stream) ||
      prevProps.stream !== this.props.stream
    ) {
      this.load();
    }
  }

  load() {
    // Pause the previous stream
    if (this.videoView.current?.srcObject) {
      this.videoView.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
    }

    setTimeout(() => {
      this.videoView.current.srcObject = this.props.stream;
      this.videoView.current.onloadedmetadata = (e) => {
        this.videoView.current.play();
      };
    }, 0);
  }

  render() {
    const {
      props: { muted = false, style = {} },
    } = this;

    return (
      <video
        ref={this.videoView}
        autoPlay
        muted={muted}
        style={{ maxWidth: "100%", maxHeight: "100%", ...style }}
      ></video>
    );
  }
}

class CallScreenModal extends React.Component {
  componentDidUpdate(preProps) {
    if (!preProps.onGoingCall && this.props.onGoingCall) {
      this.triggerDatastore();
    }
  }

  triggerDatastore() {
    const dataStore = getDatastore()?.dataStore;

    dataStore?.mergeData({
      elementId: "ONGOINGCALL",
      rowIndices: [0],
      rowIds: ["DEFAULT"],
      obj: {
        value: Date.now(),
        updatedAt: Date.now(),
      },
    });
  }

  setOnGoingCall = (obj, replace = false) => {
    const onGoingCall = replace
      ? obj
      : update(this.props.onGoingCall || {}, { $merge: obj });

    console.log({ onGoingCall });
    window.onGoingCall = onGoingCall;

    this.props.setScreenState({
      onGoingCall,
    });

    this.triggerDatastore();
  };

  render() {
    if (!this.props.onGoingCall) return null;
    else if (this.props.onGoingCall.callType === "call") {
      return (
        <CallScreen
          {...{ ...this.props, setOnGoingCall: this.setOnGoingCall.bind(this) }}
        />
      );
    }

    return (
      <MeetingScreen
        {...{ ...this.props, setOnGoingCall: this.setOnGoingCall.bind(this) }}
      />
    );
  }
}

const SCREEN_NAME = "CALL_SCREEN";
const mapStateToProps = (state) => ({
  onGoingCall: state.vState[SCREEN_NAME]?.onGoingCall,
  isMuted: state.vState.APP?.isMuted,
  isCameraDisabled: state.vState.APP?.isCameraDisabled,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallScreenModal);
