import React from "react";

class RenderText extends React.Component {
  state = { hover: false };

  hoverProps = {
    onMouseEnter: () => this.setState({ hover: true }),
    onMouseLeave: () => this.setState({ hover: false }),
  };

  render() {
    const {
      props: { domNode, activeTab, elementDataStore },
    } = this;

    const value = elementDataStore?.value || "";

    const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
    const hoverStyle = {
      ...(activeTabStyle || {}),
      ...(activeTab?.styleData?.hover || {}),
    };
    const style = this.state.hover ? hoverStyle : activeTabStyle;

    return (
      <div style={style} {...this.hoverProps}>
        {value}
      </div>
    );
  }
}

export default RenderText;
