class LocalDatabase {
  constructor({dbId, Storage, createUniqueId}) {
    this.dbId = dbId;
    this.storage = new Storage(dbId);
    this.createUniqueId = createUniqueId;
  }

  async load() {
    this.data = (await this.storage.get()) || {};
    return this.data;
  }

  async store() {
    if (!this.data) throw new Error('Database Not Initialised');
    return this.storage.set(this.data);
  }

  async clear() {
    await this.storage.set({});
    await this.load();
  }

  async queryById(_id, table) {
    if (!this.data) await this.load();
    return this.data[table]?.[_id];
  }

  async validateObj(obj, table) {
    if (
      !obj ||
      !table ||
      typeof obj !== 'object' ||
      typeof table !== 'string'
    ) {
      throw new Error('Invalid data format');
    }

    if (!obj._id) {
      obj._id = this.createUniqueId();
    } else if (await this.queryById(obj._id, table)) {
      throw new Error('Duplicate _id');
    }
  }

  async insert(obj, table) {
    if (!this.data) await this.load();

    await this.validateObj(obj, table);

    this.data = {
      ...this.data,
      [table]: {
        ...this.data[table],
        [obj._id]: obj,
      },
    };

    await this.store();

    return this.queryById(obj._id, table);
  }

  async updateById(_id, obj, table) {
    if (!this.data) await this.load();

    if (
      !obj ||
      !table ||
      typeof obj !== 'object' ||
      typeof table !== 'string'
    ) {
      throw new Error('Invalid data format');
    }

    if (!this.data[table]?.[_id]) {
      throw new Error('Record not found');
    }

    this.data = {
      ...this.data,
      [table]: {
        ...this.data[table],
        [_id]: {
          ...this.data[table][_id],
          ...obj,
          _id,
        },
      },
    };

    await this.store();

    return this.queryById(_id, table);
  }

  async deleteById(_id, table) {
    if (!this.data) await this.load();

    if (!table || typeof table !== 'string') {
      throw new Error('Invalid data format');
    }

    if (!this.data[table]?.[_id]) {
      throw new Error('Record not found');
    }

    delete this.data[table][_id];

    await this.store();
  }

  async createFilter(filters) {
    let filter = {};
    if (!filters || !filters.length) return filter;

    for (let i = 0; i < filters.length; ++i) {
      const x = filters[i];
      let xValue = x.value;

      if (x.name === undefined) return;

      if (x.type === 'filterGroup') {
        let list = [];
        for (let i = 0; i < x.filters.length; i++) {
          const filter = x.filters[i];
          let obj = await this.createFilter([filter]);
          list.push(obj);
        }

        if (['[OR]', '[AND]'].includes(x.name)) {
          let name = {'[OR]': '$or', '[AND]': '$and'}[x.name];
          filter = {...filter, [name]: [...(filter[name] || []), ...list]};
        }

        continue;
      }

      switch (x.condition) {
        case "Doesn't equal":
          if (isNaN(xValue)) filter[x.name] = {$ne: xValue};
          else {
            let temp = [
              {[x.name]: {$ne: xValue}},
              {[x.name]: {$ne: parseFloat(xValue)}},
            ];
            if (!filter['$and']) {
              filter['$and'] = temp;
            } else {
              filter['$and'] = [...filter['$and'], ...temp];
            }
          }
          break;
        case 'Greater Then':
          if (!isNaN(xValue)) xValue = parseFloat(xValue);
          filter[x.name] = {$gt: xValue};
          break;
        case 'Less Then':
          if (!isNaN(xValue)) xValue = parseFloat(xValue);
          filter[x.name] = {$lt: xValue};
          break;
        case 'Greater Then Or Equal To':
          if (!isNaN(xValue)) xValue = parseFloat(xValue);
          filter[x.name] = {$gte: xValue};
          break;
        case 'Less Then Or Equal To':
          if (!isNaN(xValue)) xValue = parseFloat(xValue);
          filter[x.name] = {$lte: xValue};
          break;
        case 'Contains':
          let regexString = '^' + xValue + '|' + xValue + '$|, ' + xValue + ',';
          filter[x.name] = {$regex: new RegExp(regexString)};
          break;
        case 'Contains Anything':
          if (!xValue) {
            filter[x.name] = {$regex: /.+/};
            break;
          }

          if (typeof xValue !== 'string' && typeof xValue !== 'number') break;

          xValue = xValue.toString().trim();
          xValue = xValue.replace(/ +/, ' ');

          let words = xValue.split(' ');

          let containsAnythingStr = words[0];
          for (let i = 1; i < words.length; ++i) {
            let word = words[i];

            containsAnythingStr = containsAnythingStr.concat('|' + word);
          }

          let containsAnythingRegEx = new RegExp(containsAnythingStr, 'i');
          filter[x.name] = {$regex: containsAnythingRegEx};
          break;
        default:
          if (isNaN(xValue)) filter[x.name] = xValue;
          else {
            let temp = [
              {[x.name]: xValue.toString()},
              {[x.name]: parseFloat(xValue)},
            ];
            if (!filter['$or']) {
              filter['$or'] = temp;
            } else {
              filter['$or'] = [...filter['$or'], ...temp];
            }
          }
      }
    }

    return filter;
  }

  async matchQuery(where, document) {
    const mongoKeywordHandlers = {
      $and: async (filters, document) => {
        for (let i = 0; i < filters.length; i++) {
          const filter = filters[i];
          const res = await this.matchQuery(filter, document);
          if (!res) return false;
        }
        return true;
      },
      $or: async (filters, document) => {
        for (let i = 0; i < filters.length; i++) {
          const filter = filters[i];
          const res = await this.matchQuery(filter, document);
          if (res) return true;
        }
        return false;
      },
    };

    const objectKeyHanlder = (key, val, document) => {
      if (typeof val == 'object') {
        if (val['$ne']) {
          return document[key] != val['$ne'];
        }
        if (val['$gt']) {
          return document[key] > val['$gt'];
        }
        if (val['$gte']) {
          return document[key] >= val['$gte'];
        }
        if (val['$lt']) {
          return document[key] < val['$lt'];
        }
        if (val['$lte']) {
          return document[key] <= val['$lte'];
        }
        if (val['$regex']) {
          return document[key]?.match?.(val['$regex']);
        } else {
          throw new Error('Invalid query');
        }
      } else {
        return document[key] === val;
      }
    };

    for (const key in where) {
      if (Object.hasOwnProperty.call(where, key)) {
        const val = where[key];

        if (/^\$/.test(key)) {
          let res = await mongoKeywordHandlers[key](val, document);
          if (!res) return false;
        } else {
          let res = await objectKeyHanlder(key, val, document);
          if (!res) return false;
        }
      }
    }

    return true;
  }

  async query({where = {}, countOnly, limit = 0, skip = 0, sort, table}) {
    if (!this.data) await this.load();
    let data = Object.values(this.data[table] || {});
    if (where._id) {
      let result = await this.queryById(where._id, table);
      data = result ? [result] : [];
    }

    let result = [];
    for (let i = 0; i < data.length; i++) {
      if (await this.matchQuery({$and: [where]}, data[i])) result.push(data[i]);
    }

    if (countOnly) return result.length;

    let sortBy = Object.keys(sort || {})[0] || '_id';
    let order = sort?.[sortBy] || 1;
    result.sort((a, b) => (a?.[sortBy] - b?.[sortBy]) * order);

    result = result.filter(
      (_, i) => i >= skip && (limit ? i < limit + skip : 1),
    );

    return result;
  }

  async read(data) {
    if (
      !data ||
      !(data.tableName || data.table) ||
      typeof (data.tableName || data.table) != 'string'
    ) {
      console.log(data);
      throw new Error('Missing valid input');
    }

    data.filterEqual =
      typeof data.filterEqual == 'object' ? data.filterEqual : {};
    data.filterNotEqual =
      typeof data.filterNotEqual == 'object' ? data.filterNotEqual : {};
    data.sortBy =
      typeof data.sortBy == 'string' && data.sortBy.length
        ? data.sortBy
        : undefined;
    data.order = data.order == 'dsc' ? -1 : 1;
    data.limit = data.limit && !isNaN(data.limit) ? parseInt(data.limit) : 0;
    data.skip = data.skip && !isNaN(data.skip) ? parseInt(data.skip) : 0;

    if (data.filters) {
      data.filterEqual = await this.createFilter(data.filters);
    }

    // Prepare query object
    let where = data.filterEqual || {};

    Object.keys(data.filterNotEqual).forEach(key => {
      where[key] = {$ne: data.filterNotEqual[key]};
    });
    let sortObj = {};
    if (data.sortBy) sortObj[data.sortBy] = data.order;

    const query = {
      where,
      sort: sortObj,
      table: data.tableName || data.table,
      limit: data.limit,
      skip: data.skip,
      countOnly: data.countOnly,
    };

    // console.log(JSON.stringify({query}, null, 4));

    let result = await this.query(query);

    return result;
  }
}

export default LocalDatabase;
