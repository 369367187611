import React, { Component } from "react";
import { connect } from "react-redux";

import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import CallScreenModal from "../Call/CallScreenModal";
import AppNavigator from "../../Navigators/AppNavigatorContainer";
import ScreenModal from "./ScreenModal";
import PaymentModal from "../../Components/payment-modal";

import utils from "../../Modules/utils/utils";
import api from "../../Services/Api/api";
import databaseModule from "../../Modules/database";
import AutoTrigger from "../../appxolo-engine/modules/triggers/AutoTrigger";
import NodeData from "../../appxolo-engine/modules/calculation/execution/NodeData";
import ExecuteTrigger from "../../appxolo-engine/modules/triggers/ExecuteTrigger";
import SyncDatabase from "../../appxolo-engine/modules/database/SyncDatabase";
import { getDatabase } from "../../Providers/local-databases";
import { setSyncDatabase } from "../../Providers/dbsync-module";

class ProjectRoot extends Component {
  componentDidMount() {
    const propsToPass = {
      utils: utils({ ...this.props, projectData: this.props.projectData }),
      api: api,
      databaseModule,
      NodeData,
      getDatabase,

      triggers: this.props.projectData.triggers,
      triggerLocation: "local",
    };

    this.autoTrigger = new AutoTrigger(propsToPass);
    this.executeTrigger = new ExecuteTrigger(propsToPass);
    this.syncDatabase = new SyncDatabase(propsToPass);
    setSyncDatabase(this.syncDatabase);

    this.autoTrigger.run();
  }

  componentWillUnmount() {
    this.autoTrigger.stop();
  }

  async onCallAnswer() {
    try {
      const triggers = this.props.projectData.triggers?.filter(
        (x) => x.triggerOn === "onCallAnswer" && x.triggerLocation === "local"
      );

      for (let i = 0; i < triggers?.length; i++) {
        const trigger = triggers?.[i];
        await this.executeTrigger.executeTrigger(trigger);
      }
    } catch (error) {
      console.warn("Error executing onCallAnswer trigger: ", error.message);
    }
  }

  render() {
    const {
      props: { projectData, screenModal, paymentModal },
    } = this;

    return (
      <>
        <AppNavigator screens={projectData.screens} />
        <CallScreenModal
          onCallAnswer={this.onCallAnswer.bind(this)}
          projectData={projectData}
        />

        {paymentModal ? (
          <PaymentModal
            {...{
              callback: paymentModal.callback,
              onCancel: paymentModal.onCancel,
              paymentModal,
              projectData,
              setPaymentModal: (x) =>
                this.props.setScreenState({ paymentModal: x }),
            }}
          />
        ) : screenModal ? (
          <ScreenModal
            {...{
              style: { flex: 1 },
              ...screenModal,
              visible: !!screenModal.screenId,
              setModal: (x) => this.props.setScreenState({ screenModal: x }),
            }}
          />
        ) : null}
      </>
    );
  }
}

const SCREEN_NAME = "APP";
const mapStateToProps = (state) => ({
  screenModal: state.vState.APP?.screenModal,
  paymentModal: state.vState.APP?.paymentModal,
});
const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRoot);
