import calculateWithPrecedence from "../calculation.js";
import _ from "lodash";
import { ValueTypes } from "../value-types.js";
import { LinkingValueTypes } from "../../linking/linking-obj.js";

class NodeData {
  constructor() {
    this.id = Math.random();
  }

  load(props) {
    this.props = props;

    const availableProps = {
      ...this.props,
      utils: this.props.utils,
      api: this.props.api,
      evaluateQuickSelectionValue: this.evaluateQuickSelectionValue.bind(this),
      evalFilters: this.evalFilters.bind(this),
      handleLinkings: this.handleLinkings.bind(this),
      executeExternalApiRequest: this.executeExternalApiRequest.bind(this),
      getFromPrevExternalApiRequest:
        this.getFromPrevExternalApiRequest.bind(this),
      loadExternalApiPayload: this.loadExternalApiPayload.bind(this),
    };

    this.valueTypes = new ValueTypes({ availableProps });
    this.linkingValueTypes = new LinkingValueTypes({ availableProps });
  }

  async getConditionSatisfiedData(data, options = {}) {
    if (data?.dataType === "conditional") {
      for (let i = 0; i < data?.tabs?.length; i++) {
        const conditions = data.tabs[i]?.conditions;

        if (
          !conditions?.length ||
          (await this.checkConditions(
            { conditions },
            { ...this.props, ...options }
          ))
        ) {
          return { index: i, tab: data.tabs?.[i] };
        }
      }

      return { index: -1, tab: null };
    } else {
      return { index: 0, tab: data?.tabs?.[0] || {} };
    }
  }

  async checkConditions({ conditions }, options = {}) {
    let evaluatedConditionValues = await Promise.all(
      conditions?.map(async (x) => {
        const evalulatedValueObj = await this.evalValue(x, options);
        return {
          ...x,
          value: evalulatedValueObj?.value,
          valueData: evalulatedValueObj?.data,
        };
      })
    );

    const isSatisfied = !!(await calculateWithPrecedence(
      evaluatedConditionValues
    ));

    return isSatisfied;
  }

  async evalCalculation(calculation, options) {
    if (!calculation?.length) return { value: "" };

    const evaluatedCalculationValues = await Promise.all(
      calculation?.map(async (x) => {
        const evalulatedValueObj = await this.evalValue(x, options);
        return {
          ...x,
          value: evalulatedValueObj?.value,
          valueData: evalulatedValueObj?.data,
        };
      })
    );

    const result = await calculateWithPrecedence(evaluatedCalculationValues);

    return { value: result };
  }

  evalValue({ valueType, valueObj }, options = {}) {
    return this.valueTypes.evalFunctions[valueType]?.(valueObj, options);
  }

  toStringSync(value) {
    return [null, undefined].includes(value)
      ? ""
      : typeof value === "object" && value instanceof Array
      ? value.map((x) => x?.value).join()
      : value?.toString();
  }

  async evaluateQuickSelectionValue(
    { valueType = "customText", valueObj = {} },
    options = {}
  ) {
    if (valueType === "calculation") {
      return this.evalCalculation(valueObj?.calculation, options).catch((e) =>
        console.warn(
          "error in NodeData.evaluateQuickSelectionValue.evalCalculation",
          e,
          valueType,
          valueObj
        )
      );
    } else if (valueType === "textParts") {
      const textParts = valueObj.textParts;
      const textValue = (
        await Promise.all(
          (textParts || []).map(async (part) => {
            const result = await this.evaluateQuickSelectionValue(
              part,
              options
            );

            return [null, undefined].includes(result?.value)
              ? ""
              : this.toStringSync(result?.value);
          })
        )
      )
        .join("")
        .trim();
      return { value: textValue };
    } else {
      return this.valueTypes.evalFunctions[valueType]?.(valueObj, options);
    }
  }

  evalFilters(filters, options = {}) {
    return Promise.all(
      (filters || []).map?.(async (filter) => ({
        ..._.omit(filter, ["valueObj"]),
        value: (await this.evaluateQuickSelectionValue(filter, options))?.value,
        filters:
          filter.filters && (await this.evalFilters(filter.filters, options)),
      }))
    );
  }

  async loadKeyValuePair(pairs, options) {
    let result = {};

    await Promise.all(
      (pairs || []).map(async (pair, i) => {
        const key = pair?.key;
        if (key) {
          const value = await this.evaluateQuickSelectionValue(pair, options);
          result[key] = value?.value?.toString()?.trim();
        }
      })
    );

    return result;
  }

  async loadRepeatingContainerRow(containerData, options = {}) {
    if (containerData?.dbId === "externalApi")
      return this.loadRepeatingContainerRowFromApi(containerData, options);
    else if (containerData?.dbId === "staticValues")
      return this.loadRepeatingContainerStaticValues(containerData, options);
    else if (containerData?.dbId === "webrtcStreams")
      return this.loadRepeatingContainerRowFromWebrtcStreams(
        containerData,
        options
      );
    else
      return this.loadRepeatingContainerRowFromDatabase(containerData, options);
  }

  convertApiBody(json, type) {
    if (!json || !type) {
      return json;
    } else if (type === "formData") {
      var form_data = new FormData();

      for (var key in json) {
        form_data.append(key, json[key]);
      }
      return form_data;
    } else {
      return json;
    }
  }

  getNestedJsonValue(obj, path) {
    if (!path || !path.trim()) return obj;
    return path
      .trim()
      .split(".")
      .reduce((acc, key) => (acc ? acc[key] : undefined), obj);
  }

  async loadExternalApiPayload(data, options = {}) {
    options = {
      ...options,
      evaluateQuickSelectionValue: this.evaluateQuickSelectionValue.bind(this),
      evalFilters: this.evalFilters.bind(this),
    };

    const {
      externalApiContentType,
      externalApiPathToResult,
      externalApiRequestId,
    } = data;

    let headers = {};
    if (externalApiContentType === "formData") {
      headers = {
        ...headers,
        "Content-Type": "multipart/form-data",
      };
    }

    let apiData = {
      fullPath: true,
      method: data.externalApiMethod || "GET",
      uri:
        data.externalApiUrl &&
        (await this.evaluateQuickSelectionValue(data.externalApiUrl))?.value,
      headers: {
        ...headers,
        ...(await this.loadKeyValuePair(data.externalApiHeaders, options)),
      },
      params: await this.loadKeyValuePair(data.externalApiParams, options),
      body: this.convertApiBody(
        await this.loadKeyValuePair(data.externalApiBody, options),
        externalApiContentType
      ),
      pathToResult: externalApiPathToResult,
      externalApiRequestId,
    };

    return apiData;
  }

  sleep(ms) {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(true);
      }, ms)
    );
  }

  async getFromPrevExternalApiRequest(apiData, options = {}) {
    let n = 0;

    let requestIdDataStore;
    while (1) {
      const dataStore = this.props.dataStore;
      requestIdDataStore =
        dataStore?.externalApiRequestIds?.[apiData.sourceRequestId];

      const isLoading = requestIdDataStore?.loading;

      if (
        (isLoading && n > 100) ||
        (!isLoading && (requestIdDataStore || n > 10))
      ) {
        break;
      }

      n++;
      await this.sleep(100);
    }

    const apiResult = requestIdDataStore?.apiResult || {};
    const requiredData = this.getNestedJsonValue(
      apiResult,
      apiData.externalApiPathToResult
    );

    return { value: requiredData, apiResult };
  }

  async executeExternalApiRequest(data, options = {}) {
    let apiData = options.useRowData
      ? data
      : await this.loadExternalApiPayload(data, options);

    const dataStore = this.props.dataStore;
    if (dataStore && apiData.externalApiRequestId) {
      const requestIdDataStore =
        dataStore.externalApiRequestIds[apiData.externalApiRequestId];
      if (!requestIdDataStore) {
        dataStore.externalApiRequestIds = {
          ...dataStore.externalApiRequestIds,
          [apiData.externalApiRequestId]: {
            elementId: this.props.domNode?.id,
            loading: true,
          },
        };
      } else if (requestIdDataStore.overideJSON) {
        let overideJson = { ...requestIdDataStore.overideJSON };
        if (!overideJson.uri) delete overideJson.uri;
        if (!overideJson.pathToResult) delete overideJson.pathToResult;

        apiData = this.mergeObjects(apiData, overideJson);
      }
    }

    const result = await this.executeExternalApiRequestFromRawApiData(
      apiData,
      options
    );

    if (dataStore && apiData.externalApiRequestId) {
      dataStore.externalApiRequestIds = {
        ...dataStore.externalApiRequestIds,
        [apiData.externalApiRequestId]: {
          elementId: this.props.domNode?.id,
          ...(result || {}),
          loading: false,
        },
      };
    }

    return result;
  }

  async executeExternalApiRequestFromRawApiData(apiData, options) {
    const apiResult = await this.props.api.request(apiData);

    const requiredData = this.getNestedJsonValue(
      apiResult,
      apiData.pathToResult
    );

    return { value: requiredData, apiResult };
  }

  mergeObjects(obj1, obj2) {
    for (let key in obj2) {
      if (
        obj1.hasOwnProperty(key) &&
        typeof obj1[key] === "object" &&
        typeof obj2[key] === "object"
      ) {
        this.mergeObjects(obj1[key], obj2[key]);
      } else {
        obj1[key] = obj2[key];
      }
    }
    return obj1;
  }

  async loadRepeatingContainerRowFromApi(containerData, options = {}) {
    this.loadingRepeatingContainerRow = true;
    let payloadData = containerData;

    let result;

    if (
      options.loadType === "pagination" &&
      options.pagination?.valueType === "externalApiInfinite"
    ) {
      const externalApiConfig = options.pagination.externalApi;

      const paginationApiData = await this.loadExternalApiPayload(
        externalApiConfig,
        options
      );

      const repeatingContainerApiData = await this.loadExternalApiPayload(
        containerData,
        options
      );

      const apiData = this.mergeObjects(
        repeatingContainerApiData,
        paginationApiData
      );

      result = await this.executeExternalApiRequest(apiData, {
        ...options,
        useRowData: true,
      });
    } else {
      result = await this.executeExternalApiRequest(payloadData, options);
    }

    this.loadingRepeatingContainerRow = false;

    return result;
  }

  async loadRepeatingContainerStaticValues(containerData, options = {}) {
    this.loadingRepeatingContainerRow = true;

    console.log({ containerData, options });
    const valueText = (
      await this.evaluateQuickSelectionValue(
        containerData.staticValues.valueText || {},
        options
      )
    )?.value;

    const result = {
      value: this.toValueArray(valueText)?.map((x, i) => ({
        ...x,
        index: i + 1,
      })),
    };

    this.loadingRepeatingContainerRow = false;

    console.log({ result });
    return result;
  }

  toValueArray(value) {
    switch (typeof value) {
      case "object":
        return value instanceof Array
          ? value.map((x) => (typeof x === "object" ? x : { value: x }))
          : [value];

      case "string":
        return value?.split(",").map((x) => ({ value: x.trim() }));
      default:
        return [value];
    }
  }

  async loadRepeatingContainerRowFromWebrtcStreams(
    containerData,
    options = {}
  ) {
    const onGoingCall = await this.props.utils.callModule.getOnGoingCallData();
    const streams = [
      {
        streamLocation: "local",
        stream: onGoingCall?.localStream,
        deviceId: this.props.utils.getDeviceId(),
      },
      ...(onGoingCall?.remoteStreams || [])?.map((streamData) => ({
        ...streamData,
        streamLocation: "remote",
        deviceId: streamData.peer?.deviceId,
      })),
    ]
      .filter(
        (x) =>
          x?.stream &&
          (x.streamLocation === "local" ||
            x?.peerConnection?.iceConnectionState !== "disconnected")
      )
      .map((x, i) => ({
        ...x,
        streamId: x.stream.id,
        index: i,
      }));

    this.props.setDependentElements({
      id: "ONGOINGCALL",
      rowIndices: [0],
      rowIds: ["DEFAULT"],
    });

    return { value: streams };
  }

  async loadRepeatingContainerRowFromDatabase(containerData, options = {}) {
    this.loadingRepeatingContainerRow = true;

    options = {
      ...options,
      evaluateQuickSelectionValue: this.evaluateQuickSelectionValue.bind(this),
      evalFilters: this.evalFilters.bind(this),
    };

    const dbData = {
      skip: containerData.skip || 0,
      sortby: containerData.sortBy || "_id",
      order: containerData.order === "dsc" ? -1 : 1,
      limit: containerData.limit || 0,
      filters: await this.evalFilters(containerData.filters, options),
      dbId: containerData.dbId,
      tableId: containerData.tableId,
    };

    if (containerData?.pagination?.valueType === "limit") {
      dbData.limit =
        containerData?.pagination?.valueObj?.limit || dbData.limit || 12;
      dbData.skip = 0;
    } else if (containerData?.pagination?.valueType === "page") {
      dbData.limit =
        containerData?.pagination?.valueObj?.page || dbData.limit || 12;
      dbData.skip = Math.max((options.pageNo || 1) - 1, 0) * dbData.limit;
    } else if (containerData?.pagination?.valueType === "infinite") {
      dbData.limit =
        containerData?.pagination?.valueObj?.infinite || dbData.limit || 12;
      dbData.skip = options.items?.length > 1 ? options.items?.length : 0;
    }

    if (this.props.setDependentDbTable) this.props.setDependentDbTable(dbData);

    const socketData = await this.props.databaseModule.read(dbData, {
      cache: options.disableCache
        ? {
            useCache: true,
            expiry: 1000 * 60 * 60 * 24 * 1,
            returnLatest: true,
          }
        : { useCache: true, expiry: 1000 * 60 * 60 * 24 * 1 },
    });

    let rows = socketData?.data;

    if (
      containerData?.pagination?.valueType === "infinite" &&
      options.items?.length > 1
    ) {
      rows = [...options.items.map((x) => x.row), ...(rows || [])];
    }

    this.loadingRepeatingContainerRow = false;

    return {
      value: rows,
    };
  }

  async handleLinkings(linkings, options) {
    for (let i = 0; i < linkings?.length; i++) {
      const linking = linkings[i];
      await this.handleLinking(linking, options).catch((e) =>
        console.warn("Error handeling linking: ", e.message)
      );
    }
  }

  async handleLinking(linking, options = {}) {
    const { tab: linkingTab } = await this.getConditionSatisfiedData(
      linking,
      options
    );
    console.log("handleLinking", { linking, linkingTab, options });

    if (linkingTab) {
      const { valueType, valueObj } = linkingTab?.linkingData || {};

      await this.linkingValueTypes.evalFunctions[valueType]?.(
        valueObj?.[valueType],
        { ...options, handleLinkings: this.handleLinkings.bind(this) }
      );
    }
  }
}

export default NodeData;
