import React from "react";
import { connect } from "react-redux";

import PActions from "../../../../Stores/redux/Persisted/Actions";
import UnpActions from "../../../../Stores/redux/Unpersisted/Actions";
import { deviceId as currentDeviceId } from "../../../../Providers/deviceId";

const WebrtcMedia = (props) => {
  const { domNode, activeTab, onGoingCall, elementDataStore } = props;
  if (!onGoingCall) return null;

  const activeTabStyle =
    activeTab?.styleData?.[domNode?.value?.elementType] || {};

  // const data = elementDataStore;
  // const deviceId = data?.deviceId?.value;

  const deviceId = elementDataStore?.value

  let streamData;
  if (deviceId === currentDeviceId)
    streamData = { stream: onGoingCall.localStream, isLocal: true };

  if (!streamData)
    streamData = onGoingCall.remoteStreams?.find(
      (x) => x.peer?.deviceId === deviceId
    );

  if (!streamData?.stream) return null;

  return (
    <VideoView
      stream={streamData.stream}
      muted={!!streamData?.isLocal}
      style={{ objectFit: "cover", width: "100%", ...activeTabStyle }}
    />
  );
};

class VideoView extends React.Component {
  state = {
    visible: !!this.props.stream,
  };
  videoView = React.createRef(null);

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.stream && this.props.stream) ||
      prevProps.stream !== this.props.stream
    ) {
      this.load();
    }
  }

  load() {
    // Pause the previous stream
    if (this.videoView.current?.srcObject) {
      this.videoView.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
    }

    setTimeout(() => {
      this.videoView.current.srcObject = this.props.stream;
      this.videoView.current.onloadedmetadata = (e) => {
        this.videoView.current.play();
      };
    }, 0);
  }

  render() {
    const {
      props: { muted = false, style = {} },
    } = this;

    return (
      <video
        ref={this.videoView}
        autoPlay
        muted={muted}
        style={{ maxWidth: "100%", maxHeight: "100%", ...style }}
      ></video>
    );
  }
}

const SCREEN_NAME = "CALL_SCREEN";
const mapStateToProps = (state) => ({
  onGoingCall: state.vState[SCREEN_NAME]?.onGoingCall,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebrtcMedia);
